<script src="https://js.stripe.com/v3/"></script>
<template>
  <div class="container" id="directPayment">
    <!-- {{ status }} -->
    <el-row>
      <el-col></el-col>
      <el-col v-if="status == 'succeeded'">
        <el-card shadow="never">
          <el-result icon="success" :title="statusTitle" :subTitle="statusMessage">
            <template slot="extra">
              <!-- <el-button type="success" size="medium">Success</el-button> -->
              <el-link type="success" :underline="false">success</el-link><br />
              <h3><span>Receipt Number: {{ txnNo }}</span></h3>
            </template>

          </el-result>
        </el-card>
      </el-col>
      <el-col v-else-if="status == 'processing'">
        <el-result icon="info" :title="statusTitle" :subTitle="statusMessage">
          <template slot="extra">
            <strong>Please Wait....</strong>
            <!-- <el-button type="warning" size="medium">view Invoice</el-button> -->
          </template>
        </el-result>
      </el-col>
      <el-col v-else-if="status == 'declined'">
        <el-result icon="danger" :title="statusTitle" :subTitle="statusMessage">
          <template slot="extra">
            <!-- <el-button type="warning" size="medium">view Invoice</el-button> -->
          </template>
        </el-result>
      </el-col>
      <el-col v-else>
        <el-result icon="error" :title="statusTitle" :subTitle="statusMessage">
          <template slot="extra">
            <el-button type="danger" size="medium">Try Again</el-button>
          </template>
        </el-result>
      </el-col>
      <el-col></el-col>
    </el-row>

    <!-- {{ paymentIdDetails }} -->
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import { verifyPaymentLink} from "../../utils/swirepay/api.js";
export default {
  name: 'checkout',
  data () {
    return {
      stripe:null,
      // emailAddress:'',
      // elements:null,
      // paymentInitData:{},
      // totalAmount:0,
      paymentStatus:{},
      statusTitle:'',
      statusMessage:'',
      status:'processing',
      txnNo:"",
      paymentGatewayDetails:{},
      paymentIdDetails:'',
    }
  },
  async mounted() {
    this.status="processing"
    this.statusTitle= "Processing";
    this.statusMessage= "Processing your request...."
    const sessionId = new URLSearchParams(window.location.search).get("SessionData");
    const paymentId = new URLSearchParams(window.location.search).get("sp-payment-link");
    const declineStatus = new URLSearchParams(window.location.search).get("decline");
    const location =new URLSearchParams(window.location.search).get("location");
    const template = new URLSearchParams(window.location.search).get("temp"); 
    // sp-payment-link
    if(sessionId && paymentId && template)
    {  
      this.paymentGatewayDetails=await this.getSessionInfo(sessionId,template);
      if(this.paymentGatewayDetails.pgType==='SWIREPAY')
        {
          this.checkSwirepayPaymentStatus(paymentId,this.paymentGatewayDetails.pgType)
        }
    }
    else if(declineStatus){
      // this.status="decline";
    this.status="declined"
    this.statusTitle= "Declined";
    this.statusMessage= "You have canceled this  payment sessions."
    setTimeout(() => {
            window.location.href= window.atob(location)
            // this.$router.push(location)
            }, 3000);
    }


  },
  computed: {
        ...mapGetters("paymentGateway", ["getIntegratedList","getEntityPaymentStatus"]),
        ...mapGetters("auth", ["getActiveWorkspace"]),
  },
  methods: {

    async getSessionInfo(sessionId,template){
      // await this.$store.dispatch("paymentGateway/fetchInitSystemsData","id");
      await this.$store.dispatch("paymentGateway/fetchInitSystemsDataByTemplate",template)
      if(this.getIntegratedList && this.getIntegratedList.cred_details)
      {
        let paymentSystem = this.getIntegratedList.cred_details.filter(
          (e) => e._id == sessionId
        );
        let bytes = await this.$CryptoJS.AES.decrypt(paymentSystem[0].crypto, paymentSystem[0].ivKey);
        let decryptedData = JSON.parse(bytes.toString(this.$CryptoJS.enc.Utf8));
        return {...paymentSystem[0],crypto:decryptedData};
      }
    },
    async checkSwirepayPaymentStatus(paymentId,pgType){

       this.paymentIdDetails=await verifyPaymentLink(this.paymentGatewayDetails.crypto.apiKey,paymentId,true)
    
       if(this.paymentIdDetails.entity.paymentSession.status=== "SUCCEEDED")
       {
        this.status="succeeded";
        this.txnNo=this.paymentIdDetails.entity.paymentSession.receiptNumber;
        // entityPaymentCapture
        this.statusTitle= "Success";
        this.statusMessage="Payment succeeded! Please wait ....";

          let params={
            ...this.paymentIdDetails.entity.meta,status:"SUCCESS",
            txnDetails:{
              txn_id:this.paymentIdDetails.entity.paymentSession.receiptNumber,
              gateway:pgType,
              paymentId:paymentId,
              name: this.paymentIdDetails && this.paymentIdDetails.entity&&this.paymentIdDetails.entity.customer&&this.paymentIdDetails.entity.customer.name?this.paymentIdDetails.entity.customer.name:this.paymentIdDetails.entity.name?this.paymentIdDetails.entity.name:"",
              email: this.paymentIdDetails && this.paymentIdDetails.entity&&this.paymentIdDetails.entity.customer&&this.paymentIdDetails.entity.customer.email?this.paymentIdDetails.entity.customer.email:this.paymentIdDetails.entity.email?this.paymentIdDetails.entity.email:"",
              phoneNumber:this.paymentIdDetails && this.paymentIdDetails.entity&&this.paymentIdDetails.entity.customer&&this.paymentIdDetails.entity.customer.phoneNumber?this.paymentIdDetails.entity.customer.phoneNumber:this.paymentIdDetails.entity.phoneNumber?this.paymentIdDetails.entity.phoneNumber:"",
              paymentSession:this.paymentIdDetails &&this.paymentIdDetails.entity&&this.paymentIdDetails.entity.paymentSession&&this.paymentIdDetails.entity.paymentSession.gid?this.paymentIdDetails.entity.paymentSession.gid:"",
              amount:this.paymentIdDetails &&this.paymentIdDetails.entity&&this.paymentIdDetails.entity.paymentSession&&this.paymentIdDetails.entity.paymentSession.amount?this.paymentIdDetails.entity.paymentSession.amount:0,
              amountRefunded:this.paymentIdDetails &&this.paymentIdDetails.entity&&this.paymentIdDetails.entity.paymentSession&&this.paymentIdDetails.entity.paymentSession.amountRefunded?this.paymentIdDetails.entity.paymentSession.amountRefunded:0,
              description:this.paymentIdDetails &&this.paymentIdDetails.entity&&this.paymentIdDetails.entity.paymentSession&&this.paymentIdDetails.entity.paymentSession.description?this.paymentIdDetails.entity.paymentSession.description:"",
              paymentDate:this.paymentIdDetails &&this.paymentIdDetails.entity&&this.paymentIdDetails.entity.paymentSession&&this.paymentIdDetails.entity.paymentSession.paymentDate?this.paymentIdDetails.entity.paymentSession.paymentDate:'',
              refundDate:this.paymentIdDetails &&this.paymentIdDetails.entity&&this.paymentIdDetails.entity.paymentSession&&this.paymentIdDetails.entity.paymentSession.refundDate?this.paymentIdDetails.entity.paymentSession.refundDate:'',
              status:this.paymentIdDetails &&this.paymentIdDetails.entity&&this.paymentIdDetails.entity.paymentSession&&this.paymentIdDetails.entity.paymentSession.status?this.paymentIdDetails.entity.paymentSession.status:'Not Paid',
              paymentType:this.paymentIdDetails &&this.paymentIdDetails.entity&&this.paymentIdDetails.entity.paymentSession&&this.paymentIdDetails.entity.paymentSession.paymentType?this.paymentIdDetails.entity.paymentSession.paymentType:"CARD",
              method:this.paymentIdDetails &&this.paymentIdDetails.entity&&this.paymentIdDetails.entity.paymentSession&&this.paymentIdDetails.entity.paymentSession.method?this.paymentIdDetails.entity.paymentSession.method:'',
              currency: this.paymentIdDetails &&this.paymentIdDetails.entity&&this.paymentIdDetails.entity.paymentSession&&this.paymentIdDetails.entity.paymentSession.currency&&this.paymentIdDetails.entity.paymentSession.currency.name?this.paymentIdDetails.entity.paymentSession.currency.name:'USD'
            }
          }
          await this.$store.dispatch("paymentGateway/entityPaymentCapture",params);
          if(this.getEntityPaymentStatus)
          {
            setTimeout(() => {
            window.location.href=this.paymentIdDetails.entity.meta.location
            // this.$router.push(this.paymentIdDetails.entity.meta.location)
            }, 3000);
          }
       }
       else if(this.paymentIdDetails.entity.paymentSession.status=== "FAILURE")
       {
        this.status="failure";
        this.txnNo='';
       }
    },

    async checkStatus() {
      try{
  const clientSecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );

  if (!clientSecret) {
    return;
  } 
 this.paymentStatus = await this.stripe.retrievePaymentIntent(clientSecret);
  switch (this.paymentStatus.paymentIntent.status) {
    case "succeeded":
      this.updateStatus();
      this.statusTitle= "Success";
      this.statusMessage="Payment succeeded!";
      break;
    case "processing":
      this.statusTitle= "Processing";
      this.statusMessage= "Your payment is processing."
      break;
    case "requires_payment_method":
      this.statusTitle= "Failed";
      this.statusMessage= "Your payment was not successful, please try again."
      break;
    default:
    this.statusTitle= "Failed";
      this.statusMessage= "Your payment was Failed"
      break;
  }
  this.status= this.paymentStatus.paymentIntent.status

}
catch(e)
{
  console.log("checkStatus",e)
}
},
async updateStatus(){
  // paymentStatusStore
  let payload= JSON.parse(this.paymentStatus.paymentIntent.description);
  payload.template_data.paymentType="ONLINE";
  payload.template_data.pgName="Stripe";
  payload.template_data.payId=this.paymentStatus.paymentIntent.id;
  payload.template_data.pay_secret=this.paymentStatus.paymentIntent.client_secret;
  await this.$store.dispatch("paymentGateway/paymentStatusStore",payload);
}
  }
}
</script>

<style lang="scss" scoped>
#directPayment {

  form {
    width: 30vw;
    min-width: 500px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
  }

  .hidden {
    display: none;
  }

  #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }

  #payment-element {
    margin-bottom: 24px;
  }

  /* Buttons and links */
  button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }

  button:hover {
    filter: contrast(115%);
  }

  button:disabled {
    opacity: 0.5;
    cursor: default;
  }

  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }

  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }

  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }

  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }

  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }

  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
      min-width: initial;
    }
  }
}
</style>